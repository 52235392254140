import useFetch from 'use-http'
import toast from 'react-hot-toast'
import {
    useState,
    useContext,
    useEffect,
    createContext,
    useCallback,
} from 'react'
import { apiUrl } from '../environment'
import { useAuth } from './auth'

const UserContext = createContext()

const initialState = {
    authenticated: false,
    error: null,
    loading: false,
    proof: null,
    tokens: null,
    name: null,
    address: null,
    timezone: null,
    avatar: null,
}

export function UserContextProvider({ children }) {
    const [state, setState] = useState(initialState)
    const { authToken } = useAuth()

    const { get, post, response, loading, error } = useFetch(`${apiUrl}`, {
        headers: authToken && { Authorization: `Bearer ${authToken}` },
    })

    const {
        post: postAvatar,
        loading: loadingAvatar,
        response: responseAvatar,
        error: errorAvatar,
    } = useFetch(`${apiUrl}/settings/avatar`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    })

    const updateName = async (name) => {
        const data = await post('settings', { name })

        if (error || !response.ok) {
            toast.error('Something went wrong', {
                position: 'bottom-left',
                duration: 4000,
            })
            return
        }

        const newName = data.name
        setState((prevState) => ({
            ...prevState,
            name: newName,
        }))

        toast.success('Update success!', {
            position: 'bottom-left',
            duration: 4000,
        })
    }

    const uploadAvatar = async (file) => {
        const data = new FormData()
        data.append('avatar', file)

        if (!file instanceof FormData) {
            toast.error('The file your sending is not form data', {
                position: 'bottom-left',
                duration: 4000,
            })
            return
        }

        const avatarData = await postAvatar(data)

        if (errorAvatar || !responseAvatar.ok) {
            toast.error('Something went wrong', {
                position: 'bottom-left',
                duration: 4000,
            })
            return
        }

        const avatar = avatarData.url
        setState((prevState) => ({
            ...prevState,
            avatar,
        }))

        toast.success('Succesfully uploaded!', {
            position: 'bottom-left',
            duration: 4000,
        })
    }

    const signIn = useCallback(async () => {
        setState((prevState) => ({
            ...prevState,
            loading: true,
        }))
        const data = await get('me')

        setState({
            authenticated: response.ok,
            name: data.name,
            public_proof: data.public_proof,
            address: data.address,
            timezone: data.timezone,
            avatar: data.avatar,
            tokens: data.tokens,
        })
    }, [response, get])

    useEffect(() => {
        if (authToken) {
            signIn()
        } else {
            setState(initialState)
        }
    }, [authToken, signIn])

    return (
        <UserContext.Provider
            value={{
                ...state,
                loadingAvatar,
                error,
                loading,
                updateName,
                uploadAvatar,
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

export const useUserContext = () => useContext(UserContext)
