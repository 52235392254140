import '@rainbow-me/rainbowkit/styles.css'
import Script from 'next/script'
import { useRouter } from 'next/navigation'

import {
    lightTheme,
    RainbowKitProvider,
    getDefaultWallets,
} from '@rainbow-me/rainbowkit'
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'
import { createClient, WagmiConfig, configureChains } from 'wagmi'
import { mainnet } from 'wagmi/chains'
import { ThemeProvider } from 'theme-ui'
import { CookiesProvider, useCookies } from 'react-cookie'
import { Provider } from 'use-http'
import { Toaster } from 'react-hot-toast'

import { AuthContext } from '../context/auth'
import { UserContextProvider } from '../context/user'

import { infuraKey } from '../environment'

import './global.css'
import './reset.css'
import 'swiper/css/bundle'

import theme from '../theme'

global.Buffer = global.Buffer || require('buffer').Buffer

const tokenKey = 'token'

// Wallet Connection Setup

const { chains, provider } = configureChains(
    [mainnet],
    [infuraProvider({ apiKey: infuraKey }), publicProvider()]
)

const { connectors } = getDefaultWallets({
    appName: 'TinyFaces NFT',
    projectId: '0ceeefffb4db24074b6155f1719d3284',
    chains,
})

const wagmiClient = createClient({
    provider,
    connectors,
})

function App({ Component, pageProps }) {
    const [cookies, setCookie, removeCookie] = useCookies([tokenKey])
    const router = useRouter()

    const options = {
        headers: {
            Accept: `application/json`,
        },
    }

    return (
        <Provider options={options}>
            <WagmiConfig client={wagmiClient}>
                <RainbowKitProvider
                    chains={chains}
                    theme={lightTheme({
                        accentColor: 'var(--theme-ui-colors-primary)',
                    })}
                >
                    <CookiesProvider>
                        <AuthContext.Provider
                            value={{
                                authToken: cookies[tokenKey],
                                setToken: (token) => setCookie(tokenKey, token),
                                signOut: () => {
                                    removeCookie(tokenKey)
                                    router.push('/')
                                },
                            }}
                        >
                            <UserContextProvider>
                                <ThemeProvider theme={theme}>
                                    <Component {...pageProps} />
                                    <Toaster />
                                    <Script src="https://www.googletagmanager.com/gtag/js?id=G-YVNL29B8DS" />
                                    <Script id="google-analytics">
                                        {`
                                            window.dataLayer = window.dataLayer || [];
                                            function gtag(){dataLayer.push(arguments);}
                                            gtag('js', new Date());
                                    
                                            gtag('config', 'G-YVNL29B8DS');
                                            `}
                                    </Script>
                                </ThemeProvider>
                            </UserContextProvider>
                        </AuthContext.Provider>
                    </CookiesProvider>
                </RainbowKitProvider>
            </WagmiConfig>
        </Provider>
    )
}

export default App
